.block__history {
    &-wrapper {
        display: grid;
        grid-template-columns: 65% 35%;
        @include breakpoint(tablet) {
            grid-template-columns: 100%;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
        }
    }

    &-timeline-wrapper {
        width: 85%;
        max-width: 1300px;
        padding: 3rem;

        @include breakpoint(tablet) {
            width: 100%;
            padding: 2rem;
        }

        @include breakpoint(mobileonly) {
            width: 100%;
            padding: 2rem;
        }

       
    }

    &-century  {
        font-family: $font-name;
        display: grid;
        grid-template-columns: 50% 50%;
        width: auto;
        margin: 0 auto;

        @include breakpoint(tablet) {
            display: block;
            grid-template-columns: 100%;
            width: auto;
            margin: 0 auto;
            text-align: right;
        }

        @include breakpoint(mobileonly) {
            display: block;
            grid-template-columns: 100%;
            width: auto;
            margin: 0 auto;
            text-align: right;
        }

    
        a {
            color: $white;
            background: $darkBlue;
            // padding: .5rem;
            padding: 14px;
            // font-size: $Headline-Size;
            font-size: 40px;
            margin-left: auto;
            width: max-content;
            // letter-spacing: $char-spacing-3;
            letter-spacing: 3px;
            // line-height: $Headline-LH;
            line-height: 40px;
            text-transform: uppercase;
            text-align: right;

            
        }

        a[name] {
            padding-top:14px;
        }
    }    


    &-timeline-entry {
        font-family: $font-name;
        display: grid;
        grid-template-columns: 50% 50%;
        // height: 350px;
        height: 400px;
        @include breakpoint(tablet) {
            grid-template-columns: 100%;
            height: auto;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
            height: auto;
        }
        &-asset {
            border-right: 3px solid $darkBlue;


            @include breakpoint(tablet) {
                border: 0;
               }

            @include breakpoint(mobileonly) {
               border: 0;
              }

            img.block__history-timeline-entry-circle {
                border-radius: 100%;
                max-width: 300px;
                height: auto;
                min-width: 300px;
                margin: 0 auto;
                display: block;
                max-height: 300px;
                min-height: 300px;
                -o-object-fit: cover;
                object-fit: cover;
                margin-top: 1rem;
                object-fit: cover;
                margin-bottom: 1rem;
            }

            img.block__history-timeline-entry-rectangle {
                object-fit: cover;
                min-height: 325px;
                width: 100%;
                float: right;
               
                padding-top: 1rem;
                padding-bottom: 1rem;

                @supports (display: grid) {
                    max-height: 325px;
                }
            }

        }



        &-content {
            display: table;
            max-height: 325px;
            height: 100%;
            padding-bottom: 1rem;
            padding-top: 1rem;
            
        }

        &-copy {
            display: table-cell;
            vertical-align: middle;

            h2 {
                font-size: $Headline-Size;
                line-height: $Headline-LH;
                letter-spacing: $char-spacing-3;
                color: $copyGrey;
            }

            ul {
                margin-bottom: 1.5rem;
            }

        }
    }
}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__history-wrapper {
        display: flex;
    }
    .block__history-century   {
        display: flex;
    }

    .block__history-century a {
        width: auto;
        margin: 0 auto;
        text-align: left;
    
    }

    .block__history-timeline-entry {
        display: flex;
    }
    .block__history-timeline-entry-asset {
        width: 50%;
   
    }

    .block__history-timeline-entry-asset img {
        width: 100%;
        height: 100%;
        background-position-x: center;
        background-size: cover;
        max-height: none;
        min-height: none;
    }
    .block__history-timeline-entry-content {
        width: 50%;
       
    }
}   