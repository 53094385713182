.block__board-member {

    &-wrapper {
        margin: 0 auto;
        padding-top: 2rem;
        padding-bottom: 2rem;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
            padding: 0;
        }
        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
            padding: 0;
        }
    }

    &-head-shot {
        img {
            object-position: center;
            object-fit: cover;
            // width: 100%;
            width: auto;
            max-height: 400px;
            min-height: 400px;
            height: 400px;


            @include breakpoint(tablet) {
                max-height: 400px;
                /* min-height: 400px; */
                height: auto;
                -o-object-fit: contain;
                object-fit: contain;
                width: 85%;
                display: block;
                margin: 0 auto;
            }

            @include breakpoint(mobileonly) {
                max-height: 400px;
                /* min-height: 400px; */
                height: auto;
                -o-object-fit: contain;
                object-fit: contain;
                width: 85%;
                display: block;
                margin: 0 auto;
            }

        }
    }

    &-content {
        font-family: $font-name, sans-serif;
        margin: 0 auto;
        width: 85%;
        padding-top: 2rem;
        // padding-bottom: 1rem;


        @include breakpoint(tablet) {
            max-height: 400px;
            width: 85%;
            margin: 0 auto;
            padding: 0;
            
            
        }

        @include breakpoint(mobileonly) {
            max-height: 400px;
            width: 85%;
            margin: 0 auto;
            padding: 0;
            
            
        }

        h2 {
            color: $copyGrey;
            font-size: 1.75rem;
            letter-spacing: 0.125rem;
            line-height: 2.1875rem;
            text-transform: uppercase;
            margin: 0;
            padding-bottom: .25rem;


            @include breakpoint(tablet) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }

            @include breakpoint(mobileonly) {
                font-size: 1.5rem;
                letter-spacing: .125rem;
                line-height: 2rem;
    
            }


        
        }
        h3 {
            color: $copyGrey;
            font-size: 1rem;
            line-height: 1.875rem;
            letter-spacing: 0.09375rem;
            padding-bottom: .25rem;

            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

        }

        a {
            font-size: 0.875rem;
            line-height: 1.75rem; 
            letter-spacing: 0.15625rem;
            color: $staticGreen;
            display: block;
            padding-bottom: .25rem;

            @include breakpoint(tablet) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

            @include breakpoint(mobileonly) {
                font-size: 1rem;
                line-height: 18px;
                line-height: 1.75rem;
            }

        }

        .bio {
            font-size: 0.875rem;
            line-height: 1.75rem;
            color: $staticGreen;
            text-transform: uppercase;
            letter-spacing: 0.15625rem;
            cursor: pointer;

            img {
                height: 15px;
                padding-bottom: 4px;
            }
        }

    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__board-member-wrapper {
        width: calc(45%);
        margin: 0 auto;
    }


}