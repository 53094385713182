.landing {

    .block__text-with-images {

        &-container {
            width: 85%;
            margin: 0 auto;
            padding: 0;
            padding-top: 2rem;
            padding-bottom: 2rem;

            @include breakpoint(tablet) {
                order: 2;
                width: 90%;
                margin: 0 auto;
                padding: 0;
                padding-top: 2rem;
                padding-bottom: 1rem;
            
            }
            
            
            @include breakpoint(mobileonly) {
                order: 2;
                width: 90%;
                margin: 0 auto;
                padding: 0;
                padding-top: 2rem;
                padding-bottom: 1rem;
            
            }
            

            // &:nth-child(2) {
            //     padding-top: 0;
            // }
        }

    }

      

}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .landing .block__text-with-images-container   {
        // float: left;
        // width: 60%;
        width: 85%;
        // display: block;
        // height: auto;
    }
   }

   @supports (-ms-ime-align:auto) {
 
   }
