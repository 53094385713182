.content__section-header {

    &-wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        height: 400px;
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
            height: auto;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
            height: auto;
        }

        &-image-overlay {
            background-size: cover;
            position: relative;
            background-position: center center;

            @include breakpoint(tablet) {
                height: 250px;
                display: table;
                width: 100%;
            }

            @include breakpoint(mobileonly) {
                height: 250px;
                display: table;
                width: 100%;

            }

         

            h2 {
                font-family: $font-name;
                display: table-cell;
                vertical-align: bottom;
                padding: 4.5rem;
                position: relative;
                font-size: $SH-size;
                line-height: 4rem;
                letter-spacing: $char-spacing-3;
                text-transform: uppercase;
                color: $white;

                @include breakpoint(tablet) {
                    display: table-cell;
                    vertical-align: bottom;
                
                    padding: 2rem;
                }

                @include breakpoint(mobileonly) {
                    display: table-cell;
                    vertical-align: bottom;
                
                    padding: 2rem;
                }

              
    
                a {
                    font-family: $font-name;
                    font-size: $SH-size;
                    line-height: 4rem;
                    letter-spacing: $char-spacing-3;
                    text-transform: uppercase;
                    color: $white;
                }
            }
        
            &:before {
                    position: absolute;
                    content:" ";
                    top:0;
                    left:0;
                    width:100%;
                    height:100%;
                    z-index:0;
                    background-color: rgba(117,192,243,0.6); 
            }
        }

    }  
    
    &-content {
        background: $mediumBlue;
        display: table;
        height: 100%;
        width: 100%;

        h2 {
                font-family: $font-name;
                display: table-cell;
                vertical-align: bottom;
                padding: 4.5rem;
                position: relative;
                font-size: $SH-size;
                line-height: 4rem;
                letter-spacing: $char-spacing-3;
                text-transform: uppercase;
                color: $white;

            a {
                font-size: $SH-size;
                line-height: 4rem;
                letter-spacing: $char-spacing-3;
                text-transform: uppercase;
                color: $white;
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .content__section-header-wrapper {
        justify-content: center;
        display: flex;
    }

    .content__section-header-wrapper-image-overlay {
        width: 50%;
    }

    .content__section-header-content {
        width: 50%;
    }
}    

@supports (-ms-ime-align:auto) {
  
}  
