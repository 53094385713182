
.press-release__search {

    &-form {


        

        @include breakpoint(tablet) {
            width: 100%;
          }

          
        background: $staticGreen;
        width: auto;
        font-family: $font-name;
        padding: 2rem;
        box-shadow: unset;


        input, select, textarea {
            height: 2rem;
        }

        label {

            font-size: $Button-size;
            line-height: $Button-lh;
            font-weight: $semiBold;
            color: $white;
            text-transform: uppercase;
            letter-spacing: $char-spacing-2;

        }
        input {
            background: $staticGreen;
            border: 1px solid $white;
            margin-top: .25rem;
            margin-bottom: 1rem;
            border-radius: 0px;
            color: $white;
            
            font-size: $Button-size;
            line-height: $Button-lh;
            font-weight: $semiBold;
            color: $white;
            letter-spacing: $char-spacing-2;
            padding: 5px;
            width: 100%;
            

            &::placeholder {

                font-size: $Button-size;
                line-height: $Button-lh;
                font-weight: $semiBold;
                color: $white;
                letter-spacing: $char-spacing-2;

            }
        }
        select {
            background: $staticGreen;
            border: 1px solid $white;
            margin-top: .25rem;
            border-radius: 0px;
            margin-bottom: 1rem;
            color: $white;
            font-size: $Button-size;
            line-height: $Button-lh;
            font-weight: $semiBold;
            color: $white;
            letter-spacing: $char-spacing-2;
            width: 100%;


            option {

            font-size: $Button-size;
            line-height: $Button-lh;
            font-weight: $semiBold;
            color: $white;
            letter-spacing: $char-spacing-2;

            }
        }

        button.submit {
            background: $white;
            // padding: 5px 15px 5px 15px;
            line-height: 1.875rem;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            text-transform: uppercase;
            color: $staticGreen;
            height: 2rem;
            outline: 0;
            padding-top: 0;
            border: 1px solid white;
            margin-top: .25rem;
            font-weight: $semiBold;

            &:after {
                content: " ";
                background-image: url('../svg/port_right-arrow-green.svg');
                background-size: cover;
                position: relative;
                display: inline-block;
                margin-left: 9px;
                width: 9px;
                height: 15px;
                top: 2px;
            }
        }

    }

    &-field-wrapper {
        display: grid;
        grid-template-columns: 38% 38% 20%;
        grid-gap: 2%;
    }
}


//   <form id# press-release__search-form name="press-release__search-form">
//   <label for="category">Category</label>
//   <select name="category">
//     <option value=""></option>
//     {% for category in categories %}
//       <option value="{{ category.title }}">{{ category.title }}</option>
//     {% endfor %}
//   </select>
//   <label for="q">Search</label>
//   <input type="text" name="q">
  
//   <label for="from-date">Date</label>
//   <input type="text" name="from-date">
//   <input type="text" name="to-date">
  
//   <input type="submit" value="Go">
// </form>

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .press-release__search-field-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .press-release__search-form input  {
        width: 100%;
    }
}