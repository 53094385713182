.block__text-with-images {
    color: $copyGrey;
    // padding: 2rem;
    width: 100%;
    display: block;
    font-family: $font-name;
    // padding: 3rem;
    h2 {
        font-size: 1.875rem;
        line-height: 3.125rem;
        margin: 0;
        letter-spacing: 0.1875rem;
        text-transform: uppercase;
        // padding-top: 2rem;
        padding-bottom: 2rem;

        @include breakpoint(tablet) {
            line-height: 2.75rem;
          }

        @include breakpoint(mobileonly) {
            line-height: 2.75rem;
          }

       
    }

    h3 {
        font-size: $Accordion-Cap-Size;
        line-height: $Subhead-LH;
        letter-spacing: $char-spacing-2;
        padding-bottom: 1.5rem;

    }
    figure {
            // padding-bottom: 2rem;
            // padding-top: 2rem;
            margin: 0 auto;
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            margin-top: 2rem;
            margin-bottom: 2rem;

    }

    a:hover {
        background: $hoverGreen;
    }

    img {
        margin: 0 auto;
        width: 100%;
        max-height: 500px;
        object-fit: cover;
    }

    ul, ol {
        // margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 3rem;

        li {
            font-size: 1rem;
            line-height: 1.875rem;
            // margin-bottom: 1.875rem;
            letter-spacing: 0.09375rem;

            @include breakpoint(tablet) {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 1rem;
                letter-spacing: 1.25px;
              }
            @include breakpoint(mobileonly) {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 1rem;
                letter-spacing: 1.25px;
              }

            a {
                background: $staticGreen;
                color: white;
                padding: 3px;
                transition: $hoverGreen-transition;

                @include breakpoint(tablet) {
                    padding: 1px;
                  }
                
                @include breakpoint(mobileonly) {
                    padding: 1px;
                  }
        
            }
        }     


    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: circle;
    }

    td {

        p, a {
                font-size: $Body-Size-Small;
                line-height: 1.25rem;
                letter-spacing: $Body-Char-Spacing;
                // margin: 1rem;
            }
            
    }

    p {
        font-size: 1rem;
        line-height: 1.875rem;
        margin-bottom: 1.875rem;
        letter-spacing: 0.09375rem;

        @include breakpoint(tablet) {
            font-size: 16px;
            line-height: 25px;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 1.875rem;
            letter-spacing: 1.25px;
          }
        @include breakpoint(mobileonly) {
            font-size: 16px;
            line-height: 25px;
            padding-bottom: 1.875rem;
            margin-bottom: 1.875rem;
            width: 100%;
            margin: 0 auto;
            letter-spacing: 1.25px;
          }


        &:last-of-type {
            margin-bottom: 0rem;
        }

    }

    p > a {
        background: $staticGreen;
        color: white;
        padding: 3px;
        transition: $hoverGreen-transition;

        @include breakpoint(tablet) {
            padding: 1px;
            word-break: break-word;
          }


        @include breakpoint(mobileonly) {
            padding: 1px;
            word-break: break-word;
          }

    }

    

    &-container {
        width: 60%;
        margin: 0 auto;
        padding: 3rem;
        // padding-left: 3rem;
        // padding-right: 3rem;
    }

    &-button button {
        color: #fff!important;
        text-transform: uppercase;
        background: $staticGreen;
        padding: 5px 15px 5px 15px;
        border: 0;
        cursor: pointer;
        line-height: 1.875rem;
        font-size: 0.9375rem;
        letter-spacing: 0.125rem;
        margin-top: 2rem;
        // margin-top: 1rem;
        // margin-bottom: 2rem;
        font-family: $font-name, sans-serif;
        font-weight: 600;
        transition: $hoverGreen-transition;

        @include breakpoint(tablet) {
            padding: 5px;
          }

        @include breakpoint(mobileonly) {
            padding: 5px;
          }

            &:after {
                // content: url('../svg/chevron_white.svg');
                // margin-left: 0;
                // top: 0;
                // width: auto;
                // height: 4px;
                content: " ";
                background-image: url('../svg/chevron_white.svg');
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 3px;
                margin-left: 29px;
                width: 12px;
                height: 18px;

                @include breakpoint(tablet) {
                    padding: 5px;
                    margin-left: 7px;
                  }

                @include breakpoint(mobileonly) {
                    padding: 5px;
                    margin-left: 7px;
                  }

            }


            &:hover {
                background: $hoverGreen; 

            }
        
    }

}