
.Hero {
    width: 100%;
    // margin-top: -100px;


    @include breakpoint(tablet) {
        //   margin-top: -185px;
          margin-top: 0;
        }


      @include breakpoint(mobileonly) {
        //   margin-top: -185px;
          margin-top: 0;
        }

    // #overlay {
    //     position:fixed;
    //     top:0;
    //     left:0;
    //     width:100%;
    //     height:100%;
    //     background:#000;
    //     opacity:0.8;
    //     /*background:rgba(255,255,255,0.8); or just this*/
    //     z-index:50;
    //     color:#fff;
    // }

    iframe {
        // pointer-events: none;
        pointer-events: all;
        margin-top: -100px;

        @include breakpoint(tablet) {
            pointer-events: all;
            margin-top: 0;
         }

        @include breakpoint(mobileonly) {
            pointer-events: all;
           margin-top: 0;
        }
    
    }

    .Hero-wrapper {
        height: 500px;
        max-height: 500px;
        overflow: hidden;

        @include breakpoint(tablet) {
            height: 425px;
            max-height: 100%;
            overflow: hidden;
        }

        @include breakpoint(mobileonly) {
            height: auto;
            max-height: 100%;
            overflow: hidden;
        }
    }

    .carousel {
        // background: #EEE;
      }
      
      .carousel-cell {
        width: 100%;
        height:500px;
        margin-right: 10px;
        // counter-increment: gallery-cell;

        @include breakpoint(tablet) {
            height: 425px;
        }
        @include breakpoint(mobileonly) {
            height: 300px;
        }
      }
      .flickity-button {
          display: none;
      }
      
      /* cell number */
      .carousel-cell:before {
        display: block;
        text-align: center;
        // content: counter(gallery-cell);
        line-height: 200px;
        font-size: 80px;
        color: white;
      }

    .Hero-image {
        background-position: center center;
        background-size: cover;
        // height: 65vh;
        height: 500px;
        width: 100%;
        // min-height: 65vh;
        display: table;

        @include breakpoint(tablet) {
            height: 425px;
        }
        
        @include breakpoint(mobileonly) {
            height: 300px;
        }
       
    }

    h1 {
        margin: 0;
        color: $white;
        text-transform: uppercase;
        // margin-top: 150px;
        margin-top: 100px;
        font-size: 15vw;
        margin-left: 1%;
        // margin-left: -20px;
        line-height: 100%;
        margin-bottom: auto;
        font-family: Montserrat,sans-serif;
        letter-spacing: .1875rem;
        // margin: 0;
        // color: $white;
        // text-transform: uppercase;
        // padding-top: 20vh;
        // font-size: 15vw;
        // line-height: 12vw;
        // padding-bottom: 5vh;
        // font-family: $font-name, sans-serif;
        // letter-spacing: 0.1875rem;

        @include breakpoint(tablet) {
            font-size: 7rem;
            margin-top: 0px;
            padding: 1%;
            margin-left: 0;
            // margin-left: -6px;
          }
        @include breakpoint(mobileonly) {
          font-size: 7rem;
          margin-top: 0px;
          padding: 0%;
          margin-left: 0;
        }

    }

    ol {
        margin-bottom: 0;
    }

    .fit-text-container {
        // display: table-cell;
        vertical-align: middle;
        padding-top: 0vh;
        // margin-top: 40px;
        width: 100%;
        max-width: 1300px;
        display: table-cell;

        @include breakpoint(tablet) {
            padding-top: 0;
  
            width: 100%;
            // display:block;
            overflow: hidden;
            display: table-cell;
          }

        @include breakpoint(mobileonly) {
            padding-top: 0;
  
            width: 100%;
            // display:block;
            overflow-x: hidden;
            display: table-cell;
          }
    }    

    .carousel-text {
        position: absolute;
        top: 10rem;
        // padding-top: 8vh;
        @include breakpoint(tablet) {
            top: 160px;
            // margin-top: 150px;
          }
        
          @include breakpoint(mobileonly) {
            // width: 95%;
            top: 160px;
            // top: 65px;
            // margin-top: 150px;
          }
        
        h1 {
            margin-top: 0;
            // margin-top: 10%;

            @include breakpoint(tablet) {
                
                padding-top: 0vh;
                
              }

            @include breakpoint(mobileonly) {
                
                padding-top: 0vh;
              }
            
        }
    }
}     

.Hero {
    .fit-text-container.placeholder {
        background: $footerGrey;
        padding-top: 60px;

    @include breakpoint(tablet) {
                
        padding-top: 60px;
      }
    @include breakpoint(mobileonly) {

        padding-top: 60px;
    }
    }

}

ul.crumbs {
    position: absolute;
    top: 458px;
    z-index: auto;
    padding-left: 2rem;
    color: #fff!important;
    text-transform: uppercase;
    line-height: 1.875rem;
    font-size: 0.9375rem;
    letter-spacing: 0.125rem;
    font-family: $font-name, sans-serif;
    font-weight: 600;
    margin-bottom: 0;

    @include breakpoint(tablet) {
        top: 390px;
      }

    @include breakpoint(mobileonly) {
        top: 265px;
      }
   

    li {
        display: inline;
    }

    a {
        color: white;

        // &:hover {
        //     color: $hoverGreen;
        // }
    }

   
}

// @media (max-width: 1160px) {
    .Hero h1 {
        font-size: 7.75rem;

        @include breakpoint(tablet) {
            font-size: 4rem;
          }

        @include breakpoint(mobileonly) {
            font-size: 4rem;
          }
    }
// }



// .ytp-playlist-menu-button-text {
//     @include breakpoint(mobileonly) {
//         opacity: 0;
//      }
// }

// .ytp-icon-watch-later {
//     @include breakpoint(mobileonly) {
//         visibility: hidden;
//      }

// }
// .ytp-icon-sharrow {
//     @include breakpoint(mobileonly) {
//         visibility: hidden;
//      }
// }
// .html5-video-player a {
//     @include breakpoint(mobileonly) {
//         opacity: 0;
//      }
// }

