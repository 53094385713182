.footer {
	order: 4;
    padding: 3rem 0 2rem 0;
    margin-top: 4rem;
    height: auto;
    background: $footerGrey;
    // margin-top: 5rem;

        &--address {
            text-align: center;
            display: block;
            // padding: 1rem 0rem 0 0;
            padding: 0rem 0 0;
            text-transform: unset;
            @include breakpoint(tablet) {
                width: 85%;
                margin: 0 auto;
            }

            @include breakpoint(mobileonly) {
                width: 85%;
                margin: 0 auto;
            }

        }

        nav {
            width: 100%;
            text-align: center;
        
            .footer__social-links {
                display: inline;
            }

            .footer-container.desktop {
                display: inline;
            }
            .nav--link-foot {
                    display: inline-block;
                    font-size: 1rem;
                    line-height: 1.875rem;
                    letter-spacing: 0.15625rem;
                    color: #fff;
                    font-family: $font-name, sans-serif;
                    font-weight: 500; // padding: .5rem .75rem;
                    text-transform: unset;
                    padding: 0em .75rem .75rem .75rem;
                    text-decoration: none;

                    button {
                        color: #fff!important;
                        border: 0;
                        // text-transform: uppercase;
                        background: $staticGreen;
                        padding: 5px 15px;
                        line-height: 1.875rem;
                        font-size: 0.9375rem;
                        cursor: pointer;
                        letter-spacing: 0.125rem;
                        font-family: Montserrat,sans-serif;
                        font-weight: 600;
                        transition: $hoverGreen-transition;
        
                        &:hover {
                            background: $hoverGreen;
                        }
                    }

                    @include breakpoint(tablet) {
                        padding: 0 1.5rem 1rem;
                        width: auto;
                    }
                  
                    // padding: 0 .75rem;
                    @include breakpoint(mobileonly) {
                        padding: 0 1.5rem 1rem;
                        width: auto;
                    }
                    
                   
                  }
           
                
          
            }
    


          }

	@media (min-width: 40rem) {
		grid-column: span 8;
    }
    
  
    // @media screen and (max-width: 768px) {
    //     .parent { 
    //       display: table;
    //     }
    //     .child1 {
    //       display: table-footer-group;
    //     }
    //     .child3 {
    //       display: table-header-group;
    //     }
    //   }
    a.desktop.nav--link-foot {
        &:hover {
            color: $hoverGreen;
        }
    }

      .footer-container {

            a.nav--link-foot {

                button {
                    
                    color: #fff!important;
                    border: 0;
                    width: 100%;
                    // text-transform: uppercase;
                    background: $staticGreen;
                    padding: 5px 15px;
                    line-height: 1.875rem;
                    font-size: 0.9375rem;
                        cursor: pointer;
                    letter-spacing: 0.125rem;
                    font-family: Montserrat,sans-serif;
                    font-weight: 600;
                    transition: $hoverGreen-transition;

                    @include breakpoint(tablet) {
                        width: 85%;
                        margin: 0 auto;
                        display: block;
                        
                        // display: block;
                    }

                    @include breakpoint(mobileonly) {
                        width: 85%;
                        margin: 0 auto;
                        display: block;
                        
                        // display: block;
                    }

                &:hover {
                    background: $hoverGreen;
                }
             
            }
        }
    }    

    .nav--link-foot {
        // display: inline-block;
        font-size: 1rem;
        line-height: 1.875rem;
        letter-spacing: 0.15625rem;
        color: #fff;
        font-family: $font-name, sans-serif;
        font-weight: 500; // padding: .5rem .75rem;
        text-transform: unset;
        padding: 0em .75rem .75rem .75rem;
        text-decoration: none;

        button {
            color: #fff!important;
            border: 0;
            // text-transform: uppercase;
            background: $staticGreen;
            padding: 5px 15px;
            line-height: 1.875rem;
            border: 0;
            cursor: pointer;
            font-size: 0.9375rem;
            letter-spacing: 0.125rem;
            font-family: Montserrat,sans-serif;
            font-weight: 600;
            transition: $hoverGreen-transition;

            &:hover {
                background: $hoverGreen;
            }

            .footer--address {
                display: block;
            }
        }
        @include breakpoint(tablet) {
            padding: 0 1.5rem 1rem;
            width: auto;
        }
        // padding: 0 .75rem;
        @include breakpoint(mobileonly) {
            padding: 0 1.5rem 1rem;
            width: auto;
        }
        
       
      }