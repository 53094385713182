

    table tr td {

        border-collapse: collapse;
        border-top:1px solid $staticGreen;
        border-left:1px solid $staticGreen;;
    }


    table tr td:first-child {
        border-left:0;
        width: 30%;

         @include breakpoint(tablet) {
            width: 40%;
          }

          @include breakpoint(mobileonly) {
            width: 40%;
          }
    }

    table tr:first-child td{
        border-top:0;
    }

    th, td {
        
        padding: 15px;
        // border: 1px solid $staticGreen;
        vertical-align: top;
    }

    table {
        
        font-family: $font-name;
        max-width: 1300px;
        margin: 0 auto;
        width: 70%;
        table-layout: fixed;
        margin-top: 2rem;
        margin-bottom: 2rem;
    
        figcaption {
            display: none;
        }

        @include breakpoint(tablet) {
            width: 100%;
          }

          @include breakpoint(mobileonly) {
            width: 100%;
          }

        a {
            background: $staticGreen;
            color: white;
            padding: 2px;
            transition: $hoverGreen-transition;
            word-break: break-word;

            &:hover {
                background: $hoverGreen;
                color: white;
            }
            @include breakpoint(tablet) {
                padding: 1px;
                
              }

            @include breakpoint(mobileonly) {
                padding: 1px;
                
              }
        }

        ol {
            list-style: decimal;
            // margin: 1rem;
            li {
                font-size: $Body-Size-Small;
                line-height: 1.25rem;
                letter-spacing: $Body-Char-Spacing;
                margin-left: 0px;
                margin-bottom: .4rem;

                a {
                    background: $staticGreen;
                    color: white;
                    padding: 3px;
                    transition: $hoverGreen-transition;

                    &:hover {
                        background: $hoverGreen;
                        color: white;
                    }
                 
                }
            }
        }

        ul {
            list-style: circle;
            margin: 1rem;
            li {
                font-size: $Body-Size-Small;
                line-height: 1.25rem;
                letter-spacing: $Body-Char-Spacing;
                margin-left: 0px;
                margin-bottom: .4rem;

                a {
                    background: $staticGreen;
                    color: white;
                    padding: 2px;
                    transition: $hoverGreen-transition;
                    
                    &:hover {
                        background: $hoverGreen;
                        color: white;
                    }
                 
                }
            }
        }

        thead {
            
            tr {
                height: auto;
                background: $darkBlue;
            }

            th {
                // width: 15%;
                color: $white;
                font-size: $Body-Size;
                line-height: 1.5rem;
                letter-spacing: $Body-Char-Spacing;
                text-transform: uppercase;
                vertical-align: middle;
                border-right: 1px solid white;
                // padding: 1rem;

                &:last-of-type {
                    border-right: 1px solid $darkBlue;
                }
            }
        }    

        td {


            figure {
                a {
                    background: none;

                    &:hover {
                        background:none;
                    }
                }
            }

            font-size: $Body-Size;
            line-height: 1.5rem;
            letter-spacing: $Body-Char-Spacing;

            h4 {
                color: $darkBlue;
                font-size: $Body-Size;
                line-height: 1.5rem;
                letter-spacing: $Body-Char-Spacing;
                text-transform: uppercase;
                vertical-align: middle;
                text-align: right;
                // border-right: 1px solid white;

            }
            img {
                width: 100%;
                // max-width: 150px;
                height: 100%;
                margin: 0 auto;
                vertical-align: middle;
            }
            p {
                font-size: $Body-Size-Small;
                line-height: 1.25rem;
                letter-spacing: $Body-Char-Spacing;
                // margin: 1rem;
            }
            ol {
                list-style: decimal;
                // margin: 1rem;
                li {
                    font-size: $Body-Size-Small;
                    line-height: 1.25rem;
                    letter-spacing: $Body-Char-Spacing;
                    margin-left: 0px;
                    margin-bottom: .4rem;

                    a {
                        background: $staticGreen;
                        color: white;
                        padding: 2px;
                        transition: $hoverGreen-transition;

                        &:hover {
                            background: $hoverGreen;
                            color: white;
                        }
                     
                    }
                }
            }

            ul {
                list-style: circle;
                margin: 1rem;
                li {
                    font-size: $Body-Size-Small;
                    line-height: 1.25rem;
                    letter-spacing: $Body-Char-Spacing;
                    margin-left: 0px;
                    margin-bottom: .4rem;

                    a {
                        background: $staticGreen;
                        color: white;
                        padding: 3px;
                        transition: $hoverGreen-transition;

                        &:hover {
                            background: $hoverGreen;
                            color: white;
                        }
                     
                    }
                }
            }

        }
    }

    .layout-ocean-carriers {

        table {

            width: 85%;
            table-layout: auto;
            

        }






        

    }


    /* responsive tables */
.layout-cruise-lines-itineraries table {
    width: 100%;
    border: 1px solid $staticGreen;
    border-collapse: collapse;
    table-layout: fixed;

    th, td {
        padding: 10px;
        border-top: 1px solid $staticGreen;
    }
    thead {
        background: #eee;
        border-bottom: 3px solid $darkBlue;
    }
    tr:nth-child(even) {
        // background: #f5f5f5;
    }
}

@media (max-width: 1199px) {
	.container {
		width: auto;
		padding: 0 10px;
	}
}

@media (max-width: 767px) {

    .layout-cruise-lines-itineraries table {
        border: 0px;
    }
    


.layout-cruise-lines-itineraries td, .layout-cruise-lines-itineraries th {
    border: 0;
}

.layout-cruise-lines-itineraries table tr td:first-child {
    border-left: 0;
}
.layout-cruise-lines-itineraries table td img {
    padding: 1rem;
}

.layout-cruise-lines-itineraries table td figure {
    margin: 0 auto;
    width: 200px;
}
.layout-cruise-lines-itineraries table {
   
        width: 90%;
        margin: 0 auto;

         thead {
                display: none;
            }
             tr,th, td {
                display: block;
            }
         td {
                border-top: none;
                border-left: none;
            }

            td p, td a  {
                font-size: 1rem;
                line-height: 1.5rem;

            }

            td ol  {
              list-style: decimal;

              li {
                font-size: 1rem;
                line-height: 1.5rem;
              }

            }


            td ul  {
                list-style: circle;
                
                li {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
  
              }
         tr td:first-child {
                border-top: 1px solid $staticGreen;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 2rem;
            }
             tr td:first-child {
                font-weight: 300;
                
            }
             td:before {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 2rem;
            }
         td:nth-of-type(1):before {
            content: "Cruise Lines:";
        }
         td:nth-of-type(2):before {
            content: "Homeported Vessel:";
        }
         td:nth-of-type(3):before {
            content: "Capacity:";
        }
         td:nth-of-type(4):before {
            content: "Program:";
        }
         td:nth-of-type(5):before {
            content: "Schedule:";
        }
     td:nth-of-type(6):before {
            content: "Cruise Iteneraries:";
        }

     tr td:first-child {
            width: auto;
    }
}
}



    /* responsive tables */
    .layout-ocean-carriers table {
        width: 100%;
        border: 1px solid $staticGreen;
        border-collapse: collapse;
        table-layout: auto;
    

        th, td {
            padding: 10px;
            border-top: 1px solid $staticGreen;
            // white-space: pre-wrap;
            // word-break: break-all;

            a, p {
                // white-space: pre-wrap;
                // word-break: break-all;
            }

            a {
                word-break: unset;
            }
            
        }
        thead {
            background: #eee;
            border-bottom: 3px solid $darkBlue;
        }
        tr:nth-child(even) {
            // background: #f5f5f5;
        }
        tr td:first-child {
            width: auto;
        }

        td {
            min-width: 180px;
        }
    }
    
    @media (max-width: 1199px) {
        .container {
            width: auto;
            padding: 0 10px;
        }
    }
    @media (max-width: 1024px) {
        .layout-ocean-carriers table {

            td {
                min-width: 0px;
            }

        }    
    }
    @media (max-width: 768px) {
    
    .layout-ocean-carriers table {
        border: 0px;

        width: 90%;
        margin: 0 auto;

         thead {
                display: none;
            }
             tr,th, td {
                display: block;
            }
         td {
                border-top: none;
                border-left: none;
            }
         tr td:first-child {
                border-top: 1px solid $staticGreen;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1.25rem;
                line-height: 2rem;
            }
             tr td:first-child {
                font-weight: 300;
                
            }

            td p, td a  {
                font-size: 1rem;
                line-height: 1.5rem;

            }

            td ol  {
              list-style: decimal;

              li {
                font-size: 1rem;
                line-height: 1.5rem;
              }

            }


            td ul  {
                list-style: circle;
                
                li {
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
  
              }
             td:before {
                display: block;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 1.25rem;
                line-height: 2rem;
            }
             td:nth-of-type(1):before {
                content: "Carriers:";
            }
             td:nth-of-type(2):before {
                content: "Regions, Destinations Serviced:";
            }
             td:nth-of-type(3):before {
                content: "Frequency:";
            }
             td:nth-of-type(4):before {
                content: "Contacts:";
            }
             td:nth-of-type(5):before {
                content: "Terminal Operator:";
            }
    
         tr td:first-child {
                width: auto;
        }
    }
    }
    @media (max-width: 1024px) {
    .layout-contact-us table { 

       tr td:first-child {

        font-size: 1.25rem;
        width: 100%;
        padding-top: 2rem;

       }
        td, th {
            padding: 5px;
            padding-left: 0;
        }


             tr td {
                border-top: 0;
                border-left: 0;
                font-size: 1rem;
            }
            
             td  {
                display: block;
                font-size: 1rem;
                line-height: 1.75rem;
            }

            td  p {

                font-size: 1rem;
                line-height: 1.75rem;
            }

            td  a {

                font-size: 1rem;
                line-height: 1.75rem;
            }


            td  p > a {

                font-size: 1rem;
                line-height: 1.75rem;
            }

        }
    
    }

    .layout-contact-us {

        table {
            table-layout: auto;
            margin-left: auto;
            width: 100%;

            tr td:first-child {
                width: auto;
            }
            a {
                word-break: break-word;
            }
        }
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
        .layout-ocean-carriers table  {
            table-layout: auto;
        }

    }