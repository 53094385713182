form {
    .freeform-row .freeform-column-6 {
        @include breakpoint(tablet) {
            width: 100%;
        }


        @include breakpoint(mobileonly) {
            width: 100%;
        }
    }

    .freeform-row .freeform-column-4 {
        @include breakpoint(tablet) {
            width: 100%;
        }


        @include breakpoint(mobileonly) {
            width: 100%;
        }
    }

    .freeform-row .freeform-column {
        button {
            margin-top: 24px;
            color: #fff !important;
            text-transform: uppercase;
            background: #6cb23d;
            padding: 5px 15px;
            cursor: pointer;
            width: 25%;
            outline: 2px solid #6cb23d;
            float: right;
            line-height: 1.875rem;
            font-size: .9375rem;
            height: 4rem;
            border: 0;
            letter-spacing: 1.5px;
            letter-spacing: .125rem;
            font-family: Montserrat,sans-serif;
            font-weight: 600;
            -webkit-transition: background .3s ease;
            -o-transition: background .3s ease;
            transition: background .3s ease;

            &:hover {
                outline: 3px solid $hoverGreen;
            }

            &:focus {
                outline: 3px solid $hoverGreen;
            }

            &:active {
                outline: 3px solid $hoverGreen;
            }

            &:focus-within {
                outline: 3px solid $hoverGreen;
            }

            @include breakpoint(tablet) {
                width: 50%;
            }


            @include breakpoint(mobileonly) {
                width: 50%;
            }


            &:after {
                content: " ";
                background-image: url("../svg/chevron_white.svg");
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 3px;
                margin-left: 29px;
                width: 12px;
                height: 18px;
            }

            &:hover {
                background: $hoverGreen;
                border: 0;
            }
        }
    }

    input.numInput.cur-year {
        outline: 0;
    }

    // @include shadow-low;
    font-family: $font-name;
    letter-spacing: $Body-Char-Spacing;
    padding: 1rem;
    clear: left;
    max-width: 1300px;
    margin: 0 auto;
    width: 60%;

    @include breakpoint(tablet) {
        width: 90%;
    }


    @include breakpoint(mobileonly) {
        width: 90%;
    }


    p {
        line-height: 1.5rem;
        margin-bottom: .75rem;
        color: $copyGrey;
    }

    ul li, ol li {
        color: $copyGrey;
        margin-left: 1rem;
        line-height: 1.5rem;
        margin-bottom: .75rem;
    }

    ul {
        list-style: circle;
    }

    ol {
        list-style: decimal;
    }

    select#form-input-state {
        height: 3rem;
    }

    .freeform-row .freeform-column {
        padding: 5px 8px;

        input {
            color: $copyGrey;
            text-indent: 5px;
            height: 3rem;
            font-size: $Body-Size;
            letter-spacing: $Body-Char-Spacing;
            line-height: $Body-LH;

            @include breakpoint(mobileonly) {
                height: 3rem;
                padding: 0;
            }
        }

        .freeform-input {
            margin-top: 0.5rem;
        }

        label.freeform-label {
            color: $copyGrey;
            width: 95%;
            font-size: $Body-Size;
            width: 95%;
            line-height: 1.5rem;
            letter-spacing: $Body-Char-Spacing;
        }

        a {
            background: $staticGreen;
            color: white;
            padding: 3px;
            transition: $hoverGreen-transition;

            &:hover {
                background: $hoverGreen;
            }
        }
    }
}

fieldset {
    border-radius: 0px;
    margin: .5em 0;

    legend {
        font-weight: 700;
        padding: 0 .2em;
    }
}

.freeform-row {
    input,
    textarea,
    select {
        background-color: $white;
        border: 0;
        outline: 2px solid $staticGreen;
        border-radius: 0px;
        height: 3em;
        padding: 5px;
        width: 100%;

        @include breakpoint(tablet) {
            width: 100%;
        }


        @include breakpoint(mobileonly) {
            width: 100%;
        }


        &:hover {
            outline: 3px solid $hoverGreen;
        }

        &:focus {
            outline: 3px solid $hoverGreen;

            @include breakpoint(tablet) {
                border: 3px solid $hoverGreen;
            }
        }

        &:active {
            outline: 3px solid $hoverGreen;
        }

        &:focus-within {
            outline: 3px solid $hoverGreen;
        }
    }

    textarea {
        height: auto;
        resize: vertical;
        height: 5rem;
        color: #626262;
        text-indent: 5px;
        font-size: 1rem;
        letter-spacing: .09375rem;
        line-height: 1.875rem;
    }

    [type="color"] {
        padding: 2px;
    }

    [type="range"] {
        -webkit-appearance: none;

        &::-webkit-slider-thumb {
            @include shadow-low;

            -webkit-appearance: none;
            background-color: $white;
            border: 1px solid $color-border;
            border-radius: 3px;
            cursor: pointer;
            height: 1.3em;
            width: 10px;
        }

        &::-moz-range-thumb {
            @include shadow-low;

            -webkit-appearance: none;
            background-color: $white;
            border: 1px solid $color-border;
            border-radius: 3px;
            cursor: pointer;
            height: 2em;
            margin-top: -1em;
            width: 10px;
        }

        &::-ms-thumb {
            @include shadow-low;

            -webkit-appearance: none;
            background-color: $white;
            border: 1px solid $color-border;
            border-radius: 3px;
            cursor: pointer;
            height: 2em;
            margin-top: -1em;
            width: 10px;
        }

        &::-webkit-slider-runnable-track {
            // Black pixel
            background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==);
            background-position: left .6em;
            background-repeat: repeat-x;
        }

        &::-moz-range-track {
            // Black pixel
            background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==);
            background-position: left .6em;
            background-repeat: repeat-x;
        }

        &::-ms-track {
            background: transparent;

            // Black pixel
            background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAAAAACH5BAAAAAAALAAAAAABAAEAAAICTAEAOw==);
            background-position: left .6em;
            background-repeat: repeat-x;
            border-color: transparent;
            color: transparent;
            cursor: pointer;
            width: 100%;
        }
    }

    [type="checkbox"] {
        cursor: pointer;
        height: auto;
        width: auto;
    }

    [type="radio"],
    [type="reset"],
    [type="button"],
    [type="submit"],
    button {
        cursor: pointer;
        height: 4rem;

        // width: inherit;
   
    }

    label {
        cursor: pointer;
    }

    .toggle {
        cursor: pointer;
        position: relative;
        vertical-align: text-top;

        &::before {
            background-color: $color-toggle-off;
            border-radius: 3px;
            content: ' ';
            display: inline-block;
            height: 16px;
            transition: background 300ms;
            width: 32px;
        }

        &::after {
            background-color: $white;
            border: 1px solid $color-border;
            border-radius: 2px;
            content: ' ';
            display: inline-block;
            height: 20px;
            left: 0;
            position: absolute;
            top: -3px;
            transition: transform 150ms;
            width: 16px;
        }
    }

    .hidden:checked + .toggle {
        &::before {
            background-color: $color-toggle-on;
        }

        &::after {
            transform: translate(16px);
        }
    }

    [type="checkbox"].custom,
    [type="radio"].custom {
        left: -9999px;
        position: absolute;

        + .custom-target {
            background-color: $white;
            border: 2px solid $color-primary;
            cursor: pointer;
            display: inline-block;
            line-height: .8;
            position: relative;
        }

        &:disabled + .custom-target {
            background-color: lighten($color-disabled, 30%);
            border-color: lighten($color-disabled, 15%);

            &::after {
                color: $color-disabled;
            }
        }

        + .custom-target::after {
            color: $color-secondary;
            transition: opacity .3s ease;
        }

        &:not(:checked) + .custom-target::after {
            opacity: 0;
        }
    }

    [type="checkbox"].custom {
        + .custom-target {
            border-radius: 3px;
        }

        + .custom-target::after {
            content: '✔';
        }
    }

    [type="radio"].custom {
        + .custom-target {
            border-radius: 100%;
            height: 1.1em;
            width: 1.1em;
        }

        + .custom-target::after {
            content: '●';
        }
    }

    input::placeholder, textarea::placeholder {
        color: $copyGrey;
        font-size: $Body-Size;
        line-height: $Body-LH;
        letter-spacing: $Body-Char-Spacing;
        text-indent: 5px;

        @include breakpoint(tablet) {
            text-indent: 0px;
            padding-left: 5px;
            white-space: pre-line;
            line-height: 1.2rem;
        }


        @include breakpoint(mobileonly) {
            text-indent: 0px;
            padding-top: 5px;
            padding-bottom: 0;
            padding-left: 5px;
            top: -5px;
            position: relative;
            line-height: 100%;
            white-space: pre-line;

            // line-height: 1.2rem;
        }


        // text-transform: capitalize;
        // text-transform: uppercase;
   
    }

    label.freeform-label, label.freeform-label.freeform-required {
        color: $copyGrey;
        font-size: $Body-Size;
        line-height: $Body-LH;
        letter-spacing: $Body-Char-Spacing;
    }

    select {
        color: $copyGrey;
        font-size: $Body-Size;
        line-height: $Body-LH;
        letter-spacing: $Body-Char-Spacing;
        text-overflow: ellipsis;
        word-wrap: normal !important;
        white-space: normal;
        height: 4.5rem;

        @include breakpoint(tablet) {
            height: 7rem;
        }


        @include breakpoint(mobileonly) {
            height: 7rem;
        }


        /*TODO: Add arrow svg to select? */
        // -webkit-appearance: none;

        // &:after {
        //     content: " ";
        //     background-image: url('../svg/port_down-arrow-green.svg');
        //     background-size: cover;
        //     position: relative;
        //     display: inline-block;
        //     top: 3px;
        //     margin-left: 29px;
        //     width: 12px;
        //     height: 18px;

        // }
   
    }

    .freeform-errors, .freeform-form-has-errors {
        color: red;
        font-size: $Body-Size;
        line-height: $Body-LH;
        letter-spacing: $Body-Char-Spacing;
    }

    .press-release__search-form input, .press-release__search-form select {
        outline: unset;

        &:hover {
            outline: unset;
        }

        &:focus {
            outline: unset;
        }

        &:active {
            outline: unset;
        }

        &:focus-within {
            outline: unset;
        }

        // .freeform-row .freeform-column input {
        //     height: auto;
        // }

        // .freeform-row .freeform-column .freeform-input-only-label>.freeform-input {
        //     height: auto !important;
        // }
   
    }
}

.press-release__search-form button.submit {
    background: #fff;
    line-height: 22.5px;
    line-height: 1.875rem;
    font-size: 11.25px;
    font-size: .9375rem;
    letter-spacing: 1.5px;
    letter-spacing: .125rem;
    text-transform: uppercase;
    color: #6cb23d;
    height: 24px;
    height: 2rem;
    outline: 0;
    padding-top: 0;
    border: 1px solid #fff;
    margin-top: 3px;
    margin-top: .25rem;
    font-weight: 600;
}

.freeform-row .freeform-column .freeform-input[type=checkbox], .freeform-row .freeform-column .freeform-input[type=radio] {
    height: auto !important;
}

// .custom-select {
//     position: relative;
//     display: block;
//     max-width: 400px;
//     min-width: 180px;
//     margin: 0 auto;
//     border: 1px solid #3C1C78;
//     background-color: #16013E;
//     z-index: 10;
//   }

//   select:after {
//     position: absolute;
//     right: 0;
//     top: 0;
//     width: 50px;
//     height: 100%;
//     line-height: 38px;
//     content: "∨";
//     text-align: center;
//     color: #714BB9;
//     font-size: 24px;
//     border-left: 1px solid #3C1C78;
//     z-index: -1;
//   }

// input{
//     height:5rem;
//   }

//   input::-webkit-input-placeholder {
//   text-indent: 0px;
//   padding-left: 5px;
//   white-space: pre-line;
//   line-height: 1.2rem;
//   }
//   ::-webkit-input-placeholder { /* Chrome/Opera/Safari
//     white-space:pre-line;  
//     position:relative;
//     // top:-7px;

//   }
//   ::-moz-placeholder { /* Firefox 19+
//        white-space:pre-line;  
//     position:relative;
//     top:-7px;
//   }
//   :-ms-input-placeholder { /* IE 10+
//       white-space:pre-line;  
//     position:relative;
//     top:-7px;
//   }
//   :-moz-placeholder { /* Firefox 18-
//        white-space:pre-line;  
//     position:relative;
//     top:-7px;
//   }
.freeform-row {
    @include breakpoint(mobileonly) {
        display: block !important;
    }
}
