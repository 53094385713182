.home__icons {

    &-wrapper {
        display: flex;
        grid-gap: 3%;
        grid-template-columns: repeat(auto-fill, 14%);
        -ms-flex-pack: distribute;
        justify-content: center;
        padding-top: 0;
        padding-bottom: 0;

        @include breakpoint(tablet) {
            display: flex;
            flex-flow: row wrap;
            align-content: space-between;
            height: 100%;
            flex-wrap: wrap;
         }

        @include breakpoint(mobileonly) {
            display: flex;
            flex-flow: row wrap;
            align-content: space-between;
            height: 100%;
            flex-wrap: wrap;
         }
    }

    &-item {
        padding: 1rem;
        padding-bottom: 2rem;

        @include breakpoint(tablet) {
            flex: 50%;
         }
        @include breakpoint(mobileonly) {
            flex: 50%;
         }
        // margin: 0 auto;

        a {
            text-align: center;
            display: block;
            padding-top: 1.5rem;
            color: $darkBlue;
            font-family: $font-name, sans-serif;
            letter-spacing: 0.15625rem;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 1.875rem;
            transition: color .3s ease;

            &:hover {
                color: $hoverGreen;
            }
        }

        img {
            display: block;
            width: 80%;
            margin: 0 auto;
            transition: all .2s ease-in-out;
            @include breakpoint(tablet) {
                width: 12rem;
             }
            @include breakpoint(mobileonly) {
                width: 12rem;
             }

            &:hover {
                transform: scale(1.2);


                @include breakpoint(tablet) {
                   transform: unset;
                 }
                @include breakpoint(mobileonly) {
                    transform: unset;
                 }
            }
        }

    }
}