// .block__animated-stat {

//     &-table-wrapper {
//         font-family: $font-name;
//         background: $mediumBlue;
//         padding: 3rem;
//         height: 450px; 
//         width: 100%;
//         display: table;

//         @include breakpoint(tablet) {
//             height: 550px;
//         }

//         @include breakpoint(mobileonly) {
//             height: 550px;
//         }

//     }

//     &-wrapper {
//         width: 90%;
//         display: table-cell;
//         vertical-align: middle;
//         margin: 0 auto;
//         max-width: 1300px;
//     }

//     &-top-copy {
//         font-size: $Stat-text-size;
//         line-height: $Stat-text-LH;
//         letter-spacing: $char-spacing-2;
//         color: $white; 
//         text-align: center;
//         margin: 0 auto;
//         max-width: 1300px;
//         padding: 1rem;
//         @include breakpoint(tablet) {
//             font-size: 3rem;
//             line-height: 3.5rem;
//             margin: 1rem;
//             padding: 0;

//         }

//         @include breakpoint(mobileonly) {
//             font-size: 3rem;
//             line-height: 3.5rem;
//             margin: 1rem;
//             padding: 0;

//         }
//     }
//     &-bottom-copy {
//         font-size: $Stat-text-size;
//         line-height: $Stat-text-LH;
//         letter-spacing: $char-spacing-2;
//         color: $white; 
//         text-align: center;
//         margin: 0 auto;
//         max-width: 1300px;
//         padding: 1rem;

//         @include breakpoint(tablet) {
//             font-size: 3rem;
//             line-height: 3.5rem;
//             margin: 1rem;
//             padding: 0;

//         }


//         @include breakpoint(mobileonly) {
//             font-size: 3rem;
//             line-height: 3.5rem;
//             margin: 1rem;
//             padding: 0;

//         }

//     }
//     &-image {
//         width: 25%;
//         margin-left: auto;
//         // margin: 0 auto;
//         padding-left: 1rem;
//         padding-right: 1rem;

//         @include breakpoint(tablet) {

//             margin: 0 auto;
//          }
//          @include breakpoint(mobile) {

//             margin: 0 auto;
//          }
       

//         @media only screen and (min-width: 1600px) {

//              padding-top: .6rem;
//           }

//         img {
//             margin: 0 auto;
//         }

//     }

//     &-image-num-wrapper {
//         display: flex;
//         margin: 0 auto;
//         width: 100%;
//         // justify-content: center;
//         flex-wrap: wrap;
//         max-width: 1300px;
//     }

//     &-symbol {
//         // width: 70%;
//         padding-left: 1rem;
//         padding-right: 1rem;
//         font-size: 9.75rem;
//         line-height: 12rem;
//         letter-spacing: $char-spacing-2;
//         color: $darkBlue;
//         @include breakpoint(desktop) {

//             font-size: 8.75rem;
//          }

//          @include breakpoint(tablet) {
//             display: none;

//         }
//          @include breakpoint(mobileonly) {
//             display: none;

//         }
//     }
//     &-number {
//         // width: auto;
//         width: 45%;
//         margin-right: auto;
//         padding-left: 1rem;
//         padding-right: 1rem;
//         font-size: 9.75rem;
//         line-height: 12rem;
//         letter-spacing: $char-spacing-2;
//         color: $darkBlue;

//         @include breakpoint(desktop) {

//             font-size: 8.75rem;
            
//         }

//         @include breakpoint(tablet) {
//             text-align: center;
//             margin: 0;
//             padding: 0;
//             font-size: 4rem;
//             line-height: 8rem;
//             margin: 0 auto;
//             width: 90%;

//         }
//         // margin: 0 auto;
//         @include breakpoint(mobileonly) {
//             text-align: center;
//             margin: 0;
//             padding: 0;
//             font-size: 4rem;
//             line-height: 8rem;
//             margin: 0 auto;
//             width: 90%;

//         }


//     }
       

//     &-disclaimer {
//         font-size: $Stat-sub-size;
//         line-height: $Stat-sub-LH;
//         letter-spacing: $char-spacing-2;
//         color: $darkBlue; 
//         text-align: center;
//         padding: 1rem;
//         margin: 0 auto;
//         max-width: 1300px;
//     }


// }

// //Animated Stats Carousel
// .block__animated-stat-table-wrapper {
//     position: relative;


//     .carousel-cell {
//         width: 100%;
//         height: auto;
//         margin-right: 10px;
//     }
//     /* cell number */
//     .carousel-cell:before {
//         display: block;
//         text-align: center;
//         line-height: 200px;
//         font-size: 80px;
//         color: white;
//     }

//     button.flickity-button {
//         display: none;
//     }
//     /* position dots in gallery */
//     .flickity-page-dots {
//         top: 93%;
//         bottom: 0px;
//         position: absolute;
//         display: flex;
//         right: 25%;
//         @include breakpoint(tablet) {
//             right: 43%;

//         }

//         @include breakpoint(mobileonly) {
//             right: 43%;

//         }
       
//     }  
//     /* white circles */
//     .flickity-page-dots .dot {
//         display: none;
//             width: 18px;
//         cursor: pointer;
//         height: 18px;
//         margin: 5px;
//         opacity: 1;
//         background: $white;
//         border: 2px solid $staticGreen;
//     }
//     /* fill-in selected dot */
//     .flickity-page-dots .dot.is-selected {
//         background: $staticGreen;
//     }
// }


.block__animated-stat {

    &-table-wrapper {
        font-family: $font-name;
        background: $mediumBlue;
        padding: 3rem;
        height: 450px; 
        width: 100%;
        display: table;
        margin: 0 AUTO;
        max-width: 1300px;
        overflow: hidden;

        @include breakpoint(tablet) {
            height: 500px;
            overflow: hidden;
        }

        @include breakpoint(mobileonly) {
            height: 500px;
            padding: 0rem;
            padding-top: 2rem;
            width: 90%;
            overflow: hidden;
        }

    }

    &-wrapper {
        width: 100%;
        // width: auto;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        max-width: 1300px;
    }

    &-top-copy {
        font-size: $Stat-text-size;
        line-height: $Stat-text-LH;
        letter-spacing: $char-spacing-2;
        color: $white; 
        text-align: left;
        margin: 0 auto;
        width: 90%;
        max-width: 1300px;
        padding: 1rem;
        @include breakpoint(tablet) {
            font-size: 3rem;
            line-height: 3.5rem;
            margin: 0 auto;
            text-align: center;
            padding: 0;

        }

        @include breakpoint(mobileonly) {
            font-size: 2.25rem;
            width: 90%;
            line-height: 2.75rem;
            padding: 0;

        }
    }
    &-bottom-copy {
        font-size: $Stat-text-size;
        line-height: $Stat-text-LH;
        letter-spacing: $char-spacing-2;
        color: $white; 
        width: 90%;
        text-align: left;
        margin: 0 auto;
        max-width: 1300px;
        padding: 1rem;

        @include breakpoint(tablet) {

            margin: 0 auto;
            text-align: center;
            font-size: 3rem;
            line-height: 3.5rem;
            padding: 0;

        }


        @include breakpoint(mobileonly) {
            font-size: 2.25rem;
            width: 90%;
            line-height: 2.75rem;
            padding: 0;

        }

    }
    &-image {
        width: unset;
        margin-left: auto;
        // margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;

        @include breakpoint(tablet) {
            width: auto;

            margin: 0 auto;
            }
            @include breakpoint(mobile) {
                width: 90%;

            margin: 0 auto;
            }

            img {
                width: 160px;
                height: 160px;
                object-fit: contain;
            }

    }
    &-image-num-wrapper {
        display: flex;
        margin: 0 auto;
        width: 90%;
        // justify-content: center;
        flex-wrap: wrap;
        max-width: 1300px;
    }

    &-symbol {
        // width: 70%;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 9.75rem;
        margin: 0 auto;
        width: 90%;
        line-height: 10rem;
        letter-spacing: $char-spacing-2;
        color: $darkBlue;
        @include breakpoint(desktop) {

            font-size: 8.75rem;
         }

         @include breakpoint(tablet) {
            text-align: center;
            display: none;
            margin: 0;
            padding-left: 0;
            font-size: 4rem;
            line-height: 8rem;
            margin: 0 auto;
            width: 90%;

        }
         @include breakpoint(mobileonly) {
            text-align: center;
            margin: 0;
            display: none;
            padding-left: 0;
            font-size: 4rem;
            line-height: 8rem;
            margin: 0 auto;
            width: 90%;

        }
    }
    &-number {
     width: 90%;
        // width: 45%;
        flex-grow: 1;
        text-align: left;
        margin: 0 auto;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 9.75rem;
        // line-height: 12rem;
        line-height: 10rem;
        letter-spacing: $char-spacing-2;
        color: $darkBlue;

        @include breakpoint(desktop) {

            font-size: 8.75rem;
            
        }

        @include breakpoint(tablet) {
            text-align: center;
            margin: 0;
            padding: 0;
            font-size: 4rem;
            line-height: 6rem;
            margin: 0 auto;
            width: 90%;

        }
        // margin: 0 auto;
        @include breakpoint(mobileonly) {
            text-align: center;
            margin: auto;
            width: 90%;
            font-size: 3.25rem;
            line-height: 5rem;
            padding: 1rem;

        }


    }
       

    &-disclaimer {
        font-size: $Stat-sub-size;
        line-height: $Stat-sub-LH;
        letter-spacing: $char-spacing-2;
        color: $darkBlue; 
        text-align: left;
        padding: 1rem;
        margin: 0 auto;
        width: 90%;
        max-width: 1300px;

        @include breakpoint(tablet) {
            margin: 0 auto;
            text-align: center;
            line-height: 3rem;
            padding: 1rem;

        }

        @include breakpoint(mobileonly) {
            line-height: 2.5rem;

        }

    }


}

//Animated Stats Carousel
.block__animated-stat-table-wrapper {
    position: relative;


    .carousel-cell {
        width: 100%;
        // width: 100%;
        // height: auto;
        // margin-right: 10px;
    }
    /* cell number */
  
    button.flickity-button {
        display: none;
    }
    /* position dots in gallery */
    .flickity-page-dots {
        top: 93%;
        bottom: 0px;
        position: absolute;
        display: flex;
        right: 25%;
        @include breakpoint(tablet) {
            // right: 47%;

        }

        @include breakpoint(mobileonly) {
            // right: 43%;

        }
       
    }  
    /* white circles */
    .flickity-page-dots .dot {
      
        width: 18px;
        cursor: pointer;
        height: 18px;
        margin: 5px;
        opacity: 1;
        background: $white;
        border: 2px solid $staticGreen;
    }
    /* fill-in selected dot */
    .flickity-page-dots .dot.is-selected {
        background: $staticGreen;
    }
}
.block__animated-stat-table-wrapper {

    @include breakpoint(tablet) {
       padding: 1rem;
       height: 525px;

    }

    @include breakpoint(mobileonly) {
        padding-top: 2rem;
    }    

}



.block__animated-stat-wrapper {
    display: grid;
    grid-template-columns: 35% 65%;
    

    @include breakpoint(tablet) {
        grid-template-columns: 100%;

    }
}

.block__animated-stat-image {
    padding: 1rem;
    height: 100%;
    width: 100%;
    display: table;
    text-align: center;

    @include breakpoint(mobileonly) {
        padding: 0rem;
    }    

}

.block__animated-stat-image img {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    height: 100%;
    margin-left: auto;
    width: 275px;
    height: 275px;

    @include breakpoint(tablet) {
        margin: 0 auto;
        width: 200px;
        height: 200px

    }
    @include breakpoint(mobileonly) {
        width: 175px;
        height: 175px;
    }    
}   

.block__animated-stat-table-wrapper .flickity-page-dots {
    top: 90%;
    left: 50%;
    margin-left: -50px;

    @include breakpoint(mobileonly) {
        top: 93%;
    }
}

@media only screen and (min-width: 1600px) {

    .block__animated-stat-disclaimer {
        font-size: 1.4rem;
        line-height: 1.9rem;
    }
}
//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 

    .block__animated-stat-wrapper {

        display: flex;
    }
    .block__animated-stat-image {

        width: 35%;

    }    
    .block__animated-stat-content-wrapper {
        width: 65%;
    }
    

}    