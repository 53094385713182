.block__expandable {

    &-container {

        font-family: $font-name;
        
    }
        
    &-header {
        background: $mediumBlue;
        margin-bottom: 0;
        padding: 1rem 2rem;
        

        @include breakpoint(mobileonly) {
            padding-left: 1rem;
            padding-right: 1rem;
        }


        a {
            font-size: 3rem;
            line-height: 4rem;
            letter-spacing: $char-spacing-3;
            text-transform: uppercase;
            color: $white;
            // margin-left: 3rem;
            max-width: 1300px;

            @include breakpoint(mobileonly) {
                font-size: 2.5rem;

            }

  
        }
    }

    &-collapsed {
        
        h3.block__exandable-subheader {
            font-size: $Accordion-Cap-Size;
            // line-height: 5rem;
            color: $staticGreen;
            letter-spacing: $char-spacing-3;
            text-transform: uppercase;
            text-align: left;
            padding-left: 37%;
            line-height: 3rem;
            padding-right: 2em;
            text-indent: -2.1em;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $staticGreen;
            cursor: pointer;
            transition: $hoverGreen-transition;

            
            @include breakpoint(tablet) {
                line-height: 3rem;
                padding: 1rem;
                text-indent: 0;
              }

              @include breakpoint(mobileonly) {
                line-height: 3rem;
                padding: 1rem;
                text-indent: 0;
              }

            span.child-container {
                color: $staticGreen;
                transition: $hoverGreen-transition;
                cursor: pointer;

                &:hover {
                    color: $hoverGreen;
                }
            }

        // &:before {
        //     content: " ";
        //     background-image: url('../svg/port_down-arrow-green.svg');
        //     // background-image: url('../svg/port_up-arrow-green.svg');
        //     background-size: cover;
        //     position: relative;
        //     display: inline-block;
        //     top: 0px;
        //     margin-right: 25px;
        //     width: 20px;
        //     height: 15px;
        //     cursor: pointer;
        //     }

 

        }
    }

    &-content {
        border-bottom: 1px solid $staticGreen;
        padding-bottom: 3rem;
    }

} 

.faq__wrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;

    h3 {

        @include breakpoint(tablet) {
            padding-left: 2em;
            text-indent: -2.1em;
            line-height: 2.25rem;
            padding: 1rem;

        
        }
        @include breakpoint(mobileonly) {
            padding-left: 2em;
            text-indent: -2.1em;
            line-height: 2.25rem;
            padding: 1rem;
        }
    }

    .block__text-with-images-container {

        @include breakpoint(tablet) {
            padding-top: 0;
            }
        @include breakpoint(mobileonly) {
            padding-top: 0;

        }
    }

    .block__expandable-collapsed {
        @include breakpoint(tablet) {
            padding: 0px 2rem;
            }
        @include breakpoint(mobileonly) {
            padding: 0px 2rem;
        }
    }
}    

// a[name] {
//     padding-top:100px;
// }
