ul.sub-nav, ul.timeline-nav {
    width: auto;
    background: $staticGreen;
    padding: 2rem 3rem 3rem 3rem;
    height: max-content;
    align-self: start;


    @include breakpoint(mobileonly) {
        padding: 2rem 2.5rem 3rem;
    }
 


    li  {
        width: 100%;
        padding: .5rem;
        line-height: 2rem;
        border-bottom: 1px solid $white;
        transition: $hoverGreen-transition;
    
        a {
            color: $white;
            font-size: $Button-size;
            // line-height: $Button-lh;
            letter-spacing: $char-spacing-2;
            text-transform: uppercase;

            &:after {
                // content: url('../svg/chevron_white.svg');
                // margin-left: 0;
                // top: 0;
                // width: auto;
                // height: 4px;
                content: " ";
                background-image: url('../svg/port_down-arrow.svg');
                background-size: cover;
                position: relative;
                display: inline-block;
                top: 9px;
                width: 19px;
                float: right;
                height: 11px;

            }
        }
        
        &:hover {
            background: $hoverGreen;

        }
        
    }
}

.content__wrapper {
    display: grid;
    grid-template-columns: 60% 40%;
    // grid-template-columns: 65% 35%;

    @include breakpoint(tablet) {
        grid-template-columns: 100%;
    }

    @include breakpoint(mobileonly) {
        grid-template-columns: 100%;
    }
  
}

ul.sub-nav-external {

    li a {
        color: $white;
        font-size: $Button-size;
        // line-height: $Button-lh;
        letter-spacing: $char-spacing-2;
        text-transform: uppercase;

        &:after {
            // content: url('../svg/chevron_white.svg');
            // margin-left: 0;
            // top: 0;
            // width: auto;
            // height: 4px;
            content: " ";
            background-image: url('../svg/port_right-arrow.svg');
            background-size: cover;
            position: relative;
            display: inline-block;
            top: 6px;
            width: 10px;
            float: right;
            height: 17px;

        }
    }
}
section.side-navigation-content {

    border-left: 2px solid #6cb23d;
    margin-top: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include breakpoint(tablet) {
        padding-bottom: 0;
        border-bottom: 2px solid #6cb23d; 
        border-left: 0;
    
    }

    @include breakpoint(mobile) {
        padding-bottom: 0;
        border-bottom: 2px solid #6cb23d; 
        border-left: 0;
    
    }



    .block__text-with-images-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .block__text-with-images figure {
        margin: 0 auto;
        width: 100%;
        max-height: 500px;
        -o-object-fit: cover;
        object-fit: cover;
        margin: 0;
    }
    .block__text-with-images h2 {
        padding-top: 0rem;
        padding-bottom: 1rem;
    }    

    blockquote {
        font-size: 2.25rem;
        line-height: 3rem;
        color: $darkBlue;
        letter-spacing: $char-spacing-2-5;
        width: 85%;
        margin: 0 auto;
        padding-bottom: 2rem;
        text-align: left;
        width: 100%;

        a {
            background: $staticGreen;
            color: white;
        }
    }
}

ul.timeline-nav li a{
    text-transform: lowercase;
}


//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    ul.sub-nav, ul.timeline-nav   {
      width: 100%;
      min-width: 26em;
      max-width: 35em;
      height: 100%;

    }
    .content__wrapper {
        display: flex;
        justify-content: space-around;
    }

   }

   @supports (-ms-ime-align:auto) {
  
  
    }
