.press-release__block {

    &-container {

        display: grid;
        grid-template-columns: 30% 30% 30%;
        grid-gap: 2rem;
        justify-content: center;
        padding-top: 4rem;
        padding-bottom: 0rem;

        padding-bottom: 0rem;

        @include breakpoint(tablet) {
            width: 90%;
            margin: 0 auto;
            grid-template-columns: 100%;
        }

        @include breakpoint(mobileonly) {
            width: 90%;
            margin: 0 auto;
            grid-template-columns: 100%;
        }
      
    }    

        &-item {
            padding-bottom: 4rem;

            @include breakpoint(tablet) {
                padding-bottom: 2rem;
            }

            @include breakpoint(mobileonly) {
                padding-bottom: 2rem;
            }

            img {

                height: 250px;
                object-fit: cover;
                object-position: center center;
                width: 100%;

            }
        
          

        }
        &-rich-text {
            font-family: $font-name;

            p {
                width: 100%;
                margin: 0 auto;
                // font-size: 1rem;
                // line-height: 1.875rem;
                // max-height: 75px;
                // min-height: 75px;
                font-size: 1rem;
                line-height: 1.875rem;
                // margin-bottom: 1.875rem;
                letter-spacing: 0.09375rem;


                @include breakpoint(tablet) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 1.875rem;
                    letter-spacing: 1.25px;
                  }

                @include breakpoint(mobileonly) {
                    font-size: 16px;
                    line-height: 25px;
                    margin-bottom: 1.875rem;
                    letter-spacing: 1.25px;
                  }
            }
            

        }

        &-content {
            font-family: $font-name;

            h3, h3 a {

                font-size: $Accordion-Cap-Size;
                line-height: $Accordion-Cap-LH;
                color: $staticGreen;
                letter-spacing: $char-spacing-2;
                transition: $hoverGreen-transition;
                padding: 1rem 0 .5rem 0;

                &:hover {
                    color: $hoverGreen;
                }
                

            }


            h4.date {

                font-size: 1rem;
                letter-spacing: 0.15625rem;
                line-height: 1.875rem;
                font-weight: 300;
                font-style: italic;
                padding: 0 0 .5rem;
            }


        }

        &-pagination, &-pagination a {

            text-align: center;
            font-weight: $semiBold;
            letter-spacing: $char-spacing-2;
            color: $staticGreen;
            text-transform: uppercase;
            font-size: $Pagination-Size;
            line-height: $Pagination-LH;
            transition: $hoverGreen-transition;
            padding: 1rem;

            

            &:hover {
                color: $hoverGreen;
            }

            span.current {
                padding: 1rem;
                color: $copyGrey;
  

            }

        }    

        
}

/*TODO: Move to its own scss module for past board meetings or pdf aggregate */

.past-board-meetings__asset p {
    margin-bottom: 1rem;
}

.past-board-meetings__asset {
  display: none;
}


.landing-aggregate .block__recent-news-item button {
    
    margin-top: 1rem;
    cursor: pointer;
}

.landing-aggregate .block__recent-news-wrapper {
    padding: 2rem 0;
}

.landing-aggregate .press-release__block-pagination,.landing-aggregate  .press-release__block-pagination a {

    margin: 0 10%;

    @include breakpoint(tablet) {
        margin: 0;
     }

     @include breakpoint(mobileonly) {
        margin: 0;
     }
}

.landing-aggregate .block__recent-news-item h3 {
    color: $staticGreen;
    font-size: $Accordion-Cap-Size;
    line-height: $Accordion-Cap-LH;
    letter-spacing: $char-spacing-2;
}

.landing-aggregate .block__recent-news-item {

    margin-bottom: 1rem;

}

//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .press-release__block-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .press-release__block-container :after{
        content:'';
        flex-grow: 1;
      }

      .press-release__block-item  {
          width: 30%;
          padding: 2%;
      }

      .press-relase__block-img {
          height: auto;
          max-height: 250px;
          min-height: 250px;
          width: 100%;
          overflow-y: hidden;
          overflow-x: hidden;
      }
      .press-relase__block-img img {
        background-size: cover;
        background-position-x: center;
        width: 100%;
        height: 100%;

      }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .landing-aggregate .block__recent-news-wrapper  {
        display: flex;
        justify-content: center;
    }
    // .block__three-up-wrapper:after{
    //     content:'';
    //     flex-grow: 1;
    //   }

    //   .block__three-up-item {
    //       width: 30%;
    //   }
}