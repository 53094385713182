.gallery__images {

    &-container {
        font-family: $font-name;
        width: 85%;
        padding-bottom: 3rem;

        h2 {

            font-size: $Headline-Size;
            line-height: $Headline-LH;
            letter-spacing: $char-spacing-3;
            color: $copyGrey;
            text-transform: uppercase;
            padding-bottom: .5rem;
            padding-top: 1rem;
        }

        h3 {

            font-size: $Subhead-Size;
            line-height: $Subhead-LH;
            letter-spacing: $char-spacing-2;
            color: $copyGrey;
            padding: 2rem 0;
        }

        #photoHolder {
            width: 100%;
            height: 700px;

            @include breakpoint(tablet) {
                height: auto;
            }


            @include breakpoint(mobileonly) {
                height: auto;
            }

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                object-position: center;
            }
        }

    }

    &-image-grid {
        display: grid;
        grid-template-columns: repeat(9, 10%);
        justify-content: space-between;

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2,48%);
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: repeat(2,48%);
        }


        img {
            min-height: 102px;
            height: 102px;
            cursor: pointer;
            padding-bottom: 1rem;
            height: auto;
            object-fit: cover;
            /* height: fit-content; */

            @include breakpoint(tablet) {
                height: 240px;
                width: 100%;
                min-height: unset;
            }

            @include breakpoint(mobileonly) {
                min-height: 115px;
                width: 100%;
            }
           
        }

    }
}

.landing-gallery {

    hr {
        margin: 2rem 0;
        color: $staticGreen;
        border: none;
        height: 1px;
        background-color: $staticGreen;
    }


    .content__wrapper {
        width: 85%;

        .block__text-with-images-container {
            width: 85%;
            padding:0;
            margin: unset;
            padding-top: 3rem;
            padding-bottom: 2rem;
        }
    }// grid-template-columns: 100%;
}

.gallery__styleguide {


    &-wrapper {

        display: grid;
        grid-template-columns: 50% 50%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 85%;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
          }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
          }
    }

    &-left {

        font-family: $font-name;
        
        display: table;
        height: 100%; 

        &-content {
            display: table-cell;
            vertical-align: middle;
            padding: 0rem;

            h3 {
                font-size: $Headline-Size;
                line-height: $Headline-LH;
                letter-spacing: $char-spacing-3;
                color: $copyGrey;
                text-transform: uppercase;
                // padding-bottom: .5rem;
                padding-top: 1rem;
                margin-bottom: 1.5rem;
            }

            p {

                font-size: 1rem;
                line-height: 1.875rem;
                margin-bottom: 1.875rem;
                letter-spacing: 0.09375rem;
                margin-bottom: 1rem;

                &:last-of-type {
                    margin-bottom: 0rem;
                }

            }

            button {
                color: #fff!important;
                text-transform: uppercase;
                background: $staticGreen;
                padding: 5px 15px 5px 15px;
                border: 0;
                line-height: 1.875rem;
                cursor: pointer;
                font-size: 0.9375rem;
                letter-spacing: 0.125rem;
                margin-right: .5rem;
                margin-top: 2rem;
                // margin-top: 1rem;
                // margin-bottom: 2rem;
                font-family: $font-name, sans-serif;
                font-weight: 600;
                transition: $hoverGreen-transition;
    
         
    
                    &:hover {
                        background: $hoverGreen;
                    }
            }

        }
    }

    &-image {
        width: auto;
        margin: 0 auto;
        padding: 0rem;
        height: 450px;
        object-fit: contain;
    }
}

.gallery__videos {

    &-container {
        font-family: $font-name;
        width: 85%;

        h2 {
            font-size: $Headline-Size;
            line-height: $Headline-LH;
            letter-spacing: $char-spacing-3;
            color: $copyGrey;
            text-transform: uppercase;
            padding-bottom: .5rem;
            padding-top: 2rem;
        }
    }
    &-wrapper {
        font-family: $font-name;
        display: grid;
        grid-template-columns: 31% 31% 31%;
        width: 85%;
        /* grid-gap: 2%; */
        justify-content: space-between;

        @include breakpoint(tablet) {
            grid-template-columns: 100%;
        }

        @include breakpoint(mobileonly) {
            grid-template-columns: 100%;
        }

    }

    &-item {
        
        margin-bottom: 4rem;

        h3 {
            font-size: $Accordion-Cap-Size;
            line-height: $Accordion-Cap-LH;
            color: $staticGreen;
            letter-spacing: $char-spacing-2;
            margin-top: 1rem;
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
    .gallery__images-image-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .gallery__images-image-grid :after{
        content:'';
        flex-grow: 1;
      }

      .gallery__images-image-grid img  {
          width: 10%;
          padding: 1%;
      }

      .gallery__videos-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

      }

      .gallery__videos-wrapper :after{
        content:'';
        flex-grow: 1;
      }

      .gallery__videos-item {
        width: 30%;
        padding: 2%;
        margin-bottom: 0;

      }

      .modal-gallery .modal-bio {
          column-count: 1;
      }

      .gallery__styleguide-wrapper {
          display: flex;
          justify-content: space-evenly;
      }

      .gallery__styleguide-left {
        width: 45%;
        height: 450px;
      }
    .gallery__styleguide-image {
        width: 45%;
        height: 450px;
    }
    //   .press-relase__block-img {
    //       height: auto;
    //       max-height: 250px;
    //       min-height: 250px;
    //       width: 100%;
    //       overflow-y: hidden;
    //       overflow-x: hidden;
    //   }
    //   .press-relase__block-img img {
    //     background-size: cover;
    //     background-position-x: center;
    //     width: 100%;
    //     height: 100%;

    //   }
}