.hide {
  display: none;
}

a {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
}

a:hover {
  color: $hoverGreen;
}

.wrapper {
  // display: grid;
  grid-template: auto 1fr minmax(1rem, auto) auto / 100%;
  min-height: 100vh;
  @media (min-width: 40rem) {
    grid-template: 5rem 1fr auto / 1fr repeat(6, minmax(auto, 10rem)) 1fr;
  }
}

.header {
  order: 1;
  @media (min-width: 40rem) {
    grid-column: span 8;
  }
}

.header--inner {
  display: flex;
  // flex-direction: column;
  align-items: center;
  // padding: 1rem;
  z-index: 1;
  background: linear-gradient($menuBlack, transparent); // background-color: #fff;
  // border-bottom: 1px solid #ccc;
  @media (min-width: 40rem) {
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 5.5rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    
  }

  @include breakpoint(tablet) {
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 5.5rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    align-items: left;
    display: block;
    padding-top: 1.5rem;
    height: 184px;
    z-index: 1;
    // position: relative;
    position: fixed;
  }

  @include breakpoint(mobileonly) {
    flex-direction: row;
    position: fixed;
    width: 100%;
    height: 5.5rem;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    align-items: left;
    display: block;
    padding-top: 1.5rem;
    height: 184px;
    z-index: 1;
    // position: relative;
    position: fixed;
  }

  .left1 {

    @include breakpoint(tablet) {
      display: none;
   }
    @include breakpoint(mobileonly) {
      display: none;
   }
   
  }
}

.logo {
  display: block;
 
 


   img {
    width: 50rem;
      // display: block; 
      // transition: width 0.5s ease-out;
      transition: width 0.6s ease-out;

      @include breakpoint(tablet) {
        width: 35rem;
        padding: 0px;
        
      }
    
      @include breakpoint(mobileonly) {
        width: 23rem;
        padding: 0px;
        
      }

      &:hover {
        opacity: .7;
      }
      min-width: 1%;
      &.shrink {
        width: 40rem
      }
      &.shrink2 {
        width: 23rem;
      }
    }  

}

.logo--link {

}

.logo--image {
 


  
}

.nav {
  margin-top: 1rem;
  width: 75%;
  @media (min-width: 1300px) {
    margin-top: 0;
    margin-left: 2rem; // margin-left: auto;
  }

  @include breakpoint(tablet) {
    width: auto;
   }

  @include breakpoint(mobileonly) {
   width: auto;
  }
}

.nav--link {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.875rem;
  letter-spacing: 0.15625rem;
  color: #fff;
  text-transform: uppercase;
  font-family: $font-name, sans-serif;
  font-weight: 500; // padding: .5rem .75rem;
  padding: 0.5rem 2%;
  text-decoration: none;

  
  &:hover {
    // background-color: #eee;
    // color: #555;
  }

  @include breakpoint(tablet) {
    width: auto;
 }

  @include breakpoint(mobileonly) {
    width: auto;
 }
 
}

@media (max-width: 1140px) {
  .nav--link {
    padding: .5rem 1%;
  }
}

.main {
  // order: 2;
  // padding: 1rem;
  // background-color: #fff;
  // @media (min-width: 40rem) {
  // 	order: 3;
  // 	grid-column: 3/8;
  // 	padding: 2rem;
  // 	border-right: 1px solid #ccc;
  // }
}


.header-search {
  position: absolute;
  right: 80px;
  top: 1.5rem;

//   @include breakpoint(mobileonly) {
//     display: none;
//  }
  img {
    padding: .5rem 1.75rem;
    display: inline-block;
  }
}

.left1 {
  display: flex;
  align-items: center;
  width: 100%;
  padding-left: 1rem;

}

.left1 .logo-container {
  margin: 0 1rem;
}

.left1 .logo-container img {
  width: 2rem;
}

.left1 .title-container {
  font-size: 1.4rem;
  color: $white;
}


/* Search form */

#search-form-4 {
  height: 75%;
  padding: 0;
  max-width: none;
  margin: auto;
  width: auto;
  right: 85px;
  position: relative;
  

  @include breakpoint(tablet) {
    display: none;
 }
  @include breakpoint(mobileonly) {
    display: none;
 }
}

// form#search-form-4.mobile.tablet {
//   display: block;
// }

/* Text bar of search form */
#search-text-4 {
  border: 0;
  position: absolute;
      border-radius: 40px;
  top: 0;
  /* Width of search-text minus width of search-submit */
  right: calc(-1 * (100vw - 3.5rem - 4rem + 1px - 3.5rem));
  /*width: calc(100vw - 3.5rem - 4rem + 1px);*/  /* Width of search-text */
  width: 11rem;
  height: 75%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  padding: 0 1rem;
  background: $white;
  outline: 0;
  font-size: 1rem;
  color: #333;
}
#search-text-4:hover {

  outline: 0;
  border: 0;
}
#search-text-4:focus {

  outline: 0;
  border: 0;
}
#search-text-4:active {

  border: 0;
  outline: 0;
}
/* For mobile portrait: search-form hides all the header, including logo */
@media screen and (max-width: 400px) {
  #search-text-4 {
    width: calc(100vw - 3.5rem);
    right: calc(-1 * (100vw - 3.5rem) - 3.5rem);
  }  
}
#search-text-4.active {
  right: 3.5rem;  /* Width of search-submit */
  transition: right 0.3s;
}

/* Submit button of search form */
#search-submit-4 {
  
  height: 100%;
  width: 3.5rem;
  outline: 0;
  border: 0;
  background-color: transparent;
  position: relative;  /* In order to make z-index work */
  z-index: 1;  /* Display it in front of search-text */
}


 #search-submit-4 img {
   width: 63%;
   cursor: pointer;
 }
#search-submit-4:hover {

}


//IE11
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { 
 
  .logo img {
    max-width: 550px;
  }
  .logo img.shrink {
    max-width: 500px;
  }
 }

 @supports (-ms-ime-align: auto) {
  .logo img {
    max-width: 600px;
  }
  .logo img.shrink {
    max-width: 500px;
  }
}

